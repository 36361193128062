import React, { useEffect, useState } from "react"
import {
  Row, Col, Divider, Spin,
} from "antd"
import VideoModal from "../VideoModal"
import ReleaseVidoeCard from "../ReleaseVidoeCard"
import ResourcesPage from "../Resources/ResourcePage"

import NewResource from "./NewResource"

const OverallResources = ({ currentUser, latestReleaseNotes }) => {
  const [activeButton, setActiveButton] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [clickedVideoObject, setClickedVideoObject] = useState({})
  const [loading, setLoading] = useState(true)
  const [groupedResources, setGroupedResources] = useState([])
  const [releaseLoading, setReleaseLoading] = useState(false)
  const [headerInfo, setHeaderInfo] = useState([])

  useEffect(() => {
    APIClient.get("/overall_resources/resource_counts", (response) => {
      if (response) {
        setHeaderInfo(response)
      }
    })
  }, [])

  const fetchDataForActiveTab = () => {
    const activeHeader = headerInfo[activeButton - 1]
    const resourceTypeName = activeHeader && activeHeader.resource_type_name

    if (resourceTypeName !== "Rubrics" && resourceTypeName !== "Release") {
      APIClient.get(
        `/overall_resources/filter_by_category?resourceTypeName=${resourceTypeName}`,
        (response) => {
          setGroupedResources(response || [])

          setLoading(false)
        },
      )
    }
  }

  const fetchReleaseData = () => {
    if (
      latestReleaseNotes
      && latestReleaseNotes.length > 0
      && headerInfo[activeButton - 1]
      && headerInfo[activeButton - 1].resource_type_name === "Release"
    ) {
      setReleaseLoading(true)
      setTimeout(() => {
        setReleaseLoading(false)
      }, 2000)
    }
  }

  useEffect(() => {
    if (headerInfo.length > 0) {
      fetchDataForActiveTab()
      fetchReleaseData()
    }
  }, [activeButton, headerInfo])

  return (
    <Row justify="center" className="deployedFontFamily">
      <VideoModal
        openStatus={openModal}
        setOpenStatus={setOpenModal}
        clickedVideoObject={clickedVideoObject}
      />

      <Col xs={24} sm={24} md={24} lg={24} className="d-flex">
        <NewResource
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          headerInfo={headerInfo}
          setLoading={setLoading}
        />
      </Col>

      <Col xs={24} sm={24} md={24} lg={20}>
        <Divider />
      </Col>

      <Col xs={24} sm={24} md={24} lg={20}>
        {headerInfo[activeButton - 1]
        && headerInfo[activeButton - 1].resource_type_name === "Release" ? (
            releaseLoading ? (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            ) : (
              <ReleaseVidoeCard
                latestReleaseNotes={latestReleaseNotes}
                currentUser={currentUser}
                setOpenModal={setOpenModal}
                setClickedVideoObject={setClickedVideoObject}
              />
            )
          ) : (
            <ResourcesPage
              tab={activeButton}
              groupedResources={groupedResources}
              resourceType={
              headerInfo[activeButton - 1]
                ? headerInfo[activeButton - 1].resource_type_name
                : undefined
            }
              loading={loading}
            />
          )}
      </Col>
    </Row>
  )
}

export default OverallResources
