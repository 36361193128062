import React, { useEffect, useState } from "react"
import { Button, Modal } from "antd"
import ReactPlayer from "react-player"
import Closecircle from "../../assets/images/Closecircle.png"
import tourimage from "../../assets/images/tourimage.png"
import commonStrings from "../commonStrings"
import { getCurrentVideoSource, formatStepCounter } from "../utilities"

const Tour = ({
  title,
  description,
  currentStep,
  totalSteps,
  handleNext,
  handleSkip,
  handleBack,
}) => {
  const [videoUrls, setVideoUrls] = useState([])

  useEffect(() => {
    APIClient.get("/s3_files", (response) => {
      if (response.status === "200") {
        setVideoUrls(response.data)
      }
    })
  }, [])

  return (
    <Modal
      keyboard
      title={currentStep === 0 ? title : null}
      visible
      className={`tour-modal ${
        currentStep !== 0 ? "step-not-zero" : "step-zero"
      }`}
      onCancel={handleSkip}
      footer={(
        <div className="tour-footer">
          <div className="tour-step-counter">
            <span>{formatStepCounter(currentStep, totalSteps)}</span>
          </div>
          <div className="tour-buttons">
            {currentStep + 1 !== totalSteps && (
              <Button
                className="skipbutton-tour"
                key="skip"
                onClick={handleSkip}
              >
                {commonStrings.skip}
              </Button>
            )}
            {currentStep > 0 && (
              <Button
                className="backbutton-tour"
                key="back"
                type="primary"
                onClick={handleBack}
              >
                {commonStrings.back}
              </Button>
            )}
            {currentStep + 1 === totalSteps ? (
              <Button
                className="finishbutton-tour"
                key="finish"
                type="primary"
                onClick={handleSkip}
              >
                {commonStrings.finish}
              </Button>
            ) : (
              <Button
                className="nextbutton-tour"
                key="next"
                type="primary"
                onClick={handleNext}
              >
                {commonStrings.next}
              </Button>
            )}
          </div>
        </div>
      )}
      closeIcon={(
        <div className="custom-close-icon">
          <img className="close-tour-icon" src={Closecircle} alt="Close Icon" />
        </div>
      )}
    >
      <div className="tutorial-content">
        <div className="video-container-tour">
          {currentStep === 0 ? (
            <img
              src={tourimage}
              alt="Tour Step"
              className="tour-image"
              style={{ width: "850px", height: "510px" }}
            />
          ) : (
            <ReactPlayer
              url={getCurrentVideoSource(videoUrls, currentStep)}
              playing
              loop
              controls={false}
              className="videos-section-tour"
            />
          )}
        </div>

        <div className="main-tour-header">
          <div className="tour-description">
            <span>{description}</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Tour
