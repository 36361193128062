import React, { useState } from "react"
import _ from "underscore"
import { Row } from "antd"
import ImplementationRubricIndicator from "./Indicator"
import "../../../assets/stylesheets/rubrics/implementation/implementationRubric.scss"

const ImplementationRubricStage = (props) => {
  const [indicators, setIndicators] = useState(props.indicators)
  const [rubric] = useState(props.rubric)
  const saveIndicator = _.debounce((indicator) => {
    showSaving()
    const id = window.location.href.split("/").pop()
    APIClient.put(
      `/implementation_rubrics/${id}/implementation_rubric_indicators/${indicator.id}`,
      {
        rating: indicator.rating,
        explanation: indicator.explanation,
        shared: indicator.shared,
      },
      () => {
        hideSaving()
      },
    )
  }, 1500)

  const rating = () => _.reduce(_.pluck(indicators, "rating"), (memo, num) => memo + num, 0)

  const percentage = () => (indicators.length > 0
    ? ((rating() / (indicators.length * 4)) * 100).toFixed(0)
    : 0)

  const overallRating = () => (
    <div
      style={{ backgroundColor: props.generalColor }}
      className="divOverAllRating overall-rating"
    >
      <div className="mx-4">
        {rating()} / {indicators.length * 4} ({percentage()}%)
      </div>
    </div>
  )
  const indexForId = (array, id) => _.findIndex(array, (i) => id === i.id)

  const updateIndicator = (indicator) => {
    const updatedIndicators = [...indicators]
    const index = indexForId(updatedIndicators, indicator.id)
    updatedIndicators[index] = indicator
    setIndicators(updatedIndicators)
    props.setUpdatedRubric(updatedIndicators)
    saveIndicator(indicator)
  }

  return (
    <div>
      {indicators.map((indicator, index) => (
        <Row justify="space-between" className="marginBottom">
          <ImplementationRubricIndicator
            rubric={rubric}
            setFlagRender={props.setFlagRender}
            lastIndicator={index === indicators.length - 1}
            key={indicator.index}
            indicator={indicator}
            disabled={props.disabled}
            updateIndicator={updateIndicator}
            generalColor={props.generalColor}
            index={index}
          />
        </Row>
      ))}

      {overallRating()}
    </div>
  )
}

export default ImplementationRubricStage
