import React from "react"
import PropTypes from "prop-types"

const IndicatorList = ({ indicators }) => (
  <div className="indicator-type-container">
    {indicators.map((type, i) => (
      <span key={i} className="indicator-type-tag">
        {type}
      </span>
    ))}
  </div>
)

IndicatorList.propTypes = {
  indicators: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default IndicatorList
