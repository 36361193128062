import React from "react"
import PropTypes from "prop-types"

const IframePlayer = ({
  src,
  width,
  height,
  title,
  allowFullScreen,
  allow,
  className,
  frameBorder,
  style,
}) => (
  <iframe
    src={src}
    width={width}
    height={height}
    title={title}
    frameBorder={frameBorder}
    allow={allow}
    allowFullScreen={allowFullScreen}
    className={className}
    style={style}
  />
)

IframePlayer.defaultProps = {
  width: "100%",
  height: "500",
  title: "Iframe player",
  allowFullScreen: true,
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
  className: "",
  frameBorder: "0",
  style: {},
}

IframePlayer.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  allowFullScreen: PropTypes.bool,
  allow: PropTypes.string,
  className: PropTypes.string,
  frameBorder: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
}

export default IframePlayer
