import React from "react"
import { Select, Tooltip } from "antd"

const { Option } = Select

const GenericTreeSelect = ({
  treeData = [],
  onChange,
  placeholder,
  maxTagCount,
  name,
}) => (
  <div className="select-filters">
    <Select
      name={name}
      key={name}
      mode="tags"
      allowClear
      onChange={onChange}
      placeholder={placeholder}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={(remainingValues) => (
        <div>
          {remainingValues.length === 1
            ? "1 selected"
            : `${remainingValues.length} selected`}
        </div>
      )}
      style={{ width: "100%" }}
      size="medium"
    >
      {treeData.map((option) => (
        <Option key={option.value} value={option.value}>
          <Tooltip title={option.label} placement="topLeft">
            <span className="option-content">{option.label}</span>
          </Tooltip>
        </Option>
      ))}
    </Select>
  </div>
)

export default GenericTreeSelect
