import React, { useEffect, useState } from "react"
import _ from "underscore"
import TextareaAutosize from "react-textarea-autosize"
import { Card, Col, Tooltip } from "antd"
import "../../../assets/stylesheets/rubrics/implementation/implementationRubric.scss"
import { RetweetOutlined } from "@ant-design/icons"
import commonStrings from "../../commonStrings"
import generateResponse from "../chatgpt/gpt"

// const debounce = (func, delay) => {
//   let debounceTimer;
//   return function () {
//     const context = this;
//     const args = arguments;
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => func.apply(context, args), delay);
//   };
// };
const ImplementationRubricIndicator = (props) => {
  const [indicator, setIndicator] = useState(props.indicator)
  const [rubric] = useState(props.rubric)
  const [generatedResponse, setGeneratedResponse] = useState("")
  const [generationStatus, setGenerationStatus] = useState("idle")
  const [hasPasted, setHasPasted] = useState(false)
  const renderDatesScore = () => {
    if (!rubric || !rubric.indicators_by_school) return <div />

    const rubricKeys = Object.keys(rubric.indicators_by_school)
    const previousRubricIds = rubricKeys.slice(0, -1)
    const previousIndicators = previousRubricIds
      .map((rubricId) => rubric.indicators_by_school[rubricId])
      .flat()
      .filter((ind) => ind.index === indicator.index && ind.rating !== null)

    const mostRecentIndicator = previousIndicators.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
    )[0]
    if (mostRecentIndicator) {
      const date = new Date(mostRecentIndicator.updated_at)
      const formattedDate = date.toLocaleDateString()
      const score = mostRecentIndicator.rating

      return (
        <div className="prevScore">
          <div>
            <span className="prevScoreHeading">
              {commonStrings.previousScore}
            </span>
          </div>
          <div>
            <span className="prevScoreNumber">{score}</span>
          </div>
          <div className="prevScoreText">
            <span>{commonStrings.on}</span> <span>{formattedDate}</span>
          </div>
        </div>
      )
    }

    return <div />
  }

  const livePopovers = () => {
    $("[data-toggle=\"popover\"]").popover({
      container: "body",
      html: true,
      trigger: "hover",
    })
  }

  useEffect(() => {
    livePopovers()
  }, [])

  const sharedResponse = (indicator) => {
    if (indicator.shared_response) {
      return (
        <Tooltip
          placement="rightTop"
          overlayInnerStyle={{
            backgroundColor: "white",
            borderRadius: "8px",
            color: "black",
            width: "500px",
            padding: "0px",
          }}
          title={(
            <div>
              <div
                className="padding10px"
                style={{ backgroundColor: "#e8e8e8" }}
              >
                {indicator.shared_response.title}
              </div>
              <div
                className="textJustify padding10px"
                dangerouslySetInnerHTML={{
                  __html: indicator.shared_response.body,
                }}
              />
            </div>
          )}
        >
          <i className="mt-1 mx-1 fa fa-question-circle" />
        </Tooltip>
      )
    }
    return null
  }

  const ratingClicked = (id, rating) => {
    if (props.disabled) return
    const updatedIndicator = { ...indicator }
    updatedIndicator.rating = updatedIndicator.rating === rating ? null : rating
    setIndicator(updatedIndicator)
    props.updateIndicator(updatedIndicator)
  }

  // const debouncedGenerateResponse = useCallback(
  //   debounce(async (inputText) => {
  //     if (inputText.length > 0) {
  //       console.log(
  //         "Debounced Function: Preparing to call generateResponse with input:",
  //         inputText
  //       );
  //       try {
  //         const response = await generateResponse(inputText);
  //         console.log(
  //           "Debounced Function: Received response from generateResponse:",
  //           response
  //         );

  //         setGeneratedResponse(response);
  //       } catch (error) {
  //         console.error(
  //           "Debounced Function: Error in generateResponse:",
  //           error
  //         );
  //       }
  //     }
  //   }, 1000),
  //   []
  // );
  // const explanationChanged = (id, e) => {
  //   const updatedIndicator = { ...indicator };
  //   updatedIndicator.explanation = e.target.value;
  //   setIndicator(updatedIndicator);
  //   props.updateIndicator(updatedIndicator);
  // };
  const resetGenerationStatus = () => {
    if (generationStatus === "generated") {
      setGenerationStatus("idle")
    }
  }
  // const explanationChangedgpt = (id, e) => {
  //   const inputText = e.target.value;
  //   // console.log("Input Changed: New value:", inputText);

  //   const updatedIndicator = { ...indicator };
  //   updatedIndicator.explanation = inputText;
  //   setIndicator(updatedIndicator);
  //   props.updateIndicator(updatedIndicator);
  //   resetGenerationStatus();
  //   if (generationStatus === "generated") {
  //     setGenerationStatus("idle");
  //   }

  //   debouncedGenerateResponse(inputText);
  // };
  const explanationChangedgpt = (id, e) => {
    const inputText = e.target.value

    const updatedIndicator = { ...indicator }
    updatedIndicator.explanation = inputText
    setIndicator(updatedIndicator)
    props.updateIndicator(updatedIndicator)

    resetGenerationStatus()
    if (hasPasted) {
      setHasPasted(false)
    }
  }

  const pasteGeneratedResponse = () => {
    if (generatedResponse && generatedResponse.trim() !== "") {
      const updatedIndicator = { ...indicator }
      updatedIndicator.explanation = generatedResponse
      setIndicator(updatedIndicator)
      props.updateIndicator(updatedIndicator)
      setGeneratedResponse("")
      setHasPasted(true)
    }
  }

  // const handleGenerateResponseClick = async () => {
  //   if (indicator.explanation.length > 0 && isTextModifiedSinceLastGeneration) {
  //     try {
  //       const response = await generateResponse(indicator.explanation);
  //       setGeneratedResponse(response);
  //     } catch (error) {
  //       console.error("Error in generateResponse:", error);
  //     }
  //   }
  // };
  const handleGenerateResponseClick = async () => {
    if (generationStatus === "idle" && indicator.explanation.length > 0) {
      setGenerationStatus("generating")
      try {
        const response = await generateResponse(indicator.explanation, 1)
        setGeneratedResponse(response)
        setGenerationStatus("generated")
      } catch (error) {
        console.error("Error in generateResponse:", error)
        setGenerationStatus("idle")
      }
    }
  }

  // const explanationChanged = async (id, e) => {
  //   const inputText = e.target.value;
  //   if (inputText.length > 0) {
  //     const updatedIndicator = { ...indicator };
  //     updatedIndicator.explanation = inputText;
  //     setIndicator(updatedIndicator);
  //     props.updateIndicator(updatedIndicator);

  //     console.log("Sending input text to generate response:", inputText);
  //     const generatedResponse = await generateResponse(inputText);
  //     console.log("Generated Response:", generatedResponse);
  //     setGeneratedResponse(generatedResponse);
  //   } else {
  //   }
  // };

  const shareResponse = (e) => {
    const updatedIndicator = { ...indicator }
    updatedIndicator.shared = e.target.checked
    setIndicator(updatedIndicator)
    props.updateIndicator(updatedIndicator)
  }

  const indicatorRating = (indicator) => {
    const array = [0, 1, 2, 3, 4]
    const tooltipData = {
      0: commonStrings.indicatorTooltip0,
      1: commonStrings.indicatorTooltip1,
      2: commonStrings.indicatorTooltip2,
      3: commonStrings.indicatorTooltip3,
      4: commonStrings.indicatorTooltip4,
    }
    return array.map((value) => {
      const text = tooltipData[value]
      if (indicator.rating !== null) {
        return (
          <Tooltip
            placement="bottom"
            title={<span style={{ fontWeight: "bolder" }}>{text}</span>}
          >
            <div
              style={
                value <= indicator.rating && indicator.rating > 0
                  ? {
                    backgroundColor: props.generalColor,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                  : {}
              }
            >
              <span
                className={`rate ${value === 4 ? "marginLeft15" : ""}`}
                onClick={() => {
                  ratingClicked(indicator.id, value)
                }}
                style={
                  indicator.rating === value
                    ? {
                      backgroundColor: props.generalColor,
                      color: "white",
                    }
                    : {}
                }
              >
                <div
                  className={value === indicator.rating ? "selected-value" : ""}
                  style={
                    value === indicator.rating
                      ? { backgroundColor: props.generalColor }
                      : {}
                  }
                >
                  <span>{value}</span>
                </div>
              </span>
            </div>
          </Tooltip>
        )
      }
      return (
        <div>
          <Tooltip
            placement="bottom"
            title={<span style={{ fontWeight: "bolder" }}>{text}</span>}
          >
            <span
              className={`rate ${value === 4 ? "margin-left30" : ""}`}
              onClick={() => ratingClicked(indicator.id, value)}
            >
              {value}
            </span>
          </Tooltip>
        </div>
      )
    })
  }
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={4} className="indicatorNumbering">
        <div className="indicatorText">Indicator</div>
        <label style={{ color: props.generalColor }} className="indicatorNum">
          {indicator.body[0]}
        </label>
      </Col>

      <Col xs={24} sm={24} md={24} lg={13} className="indicatorMiddle">
        <div className="body" dangerouslySetInnerHTML={indicator.body[1]} />
        {(_.isNumber(indicator.rating) || indicator.explanation) && (
          <>
            <div className="explanation">
              <span>
                <div className="my-3 d-flex">
                  <span
                    htmlFor={indicator.id}
                    dangerouslySetInnerHTML={indicator.label}
                    className="explanation-label"
                  />
                  {sharedResponse(indicator)}
                </div>
                <div className="mb-3">
                  {/* <TextareaAutosize
                    className="form-control textArea-div"
                    id={indicator.id}
                    value={indicator.explanation}
                    onChange={(e) => explanationChanged(indicator.id, e)}
                    disabled={props.disabled}
                  /> */}
                  <TextareaAutosize
                    className="form-control textArea-div"
                    id={indicator.id}
                    value={indicator.explanation}
                    onChange={(e) => explanationChangedgpt(indicator.id, e)}
                    disabled={props.disabled}
                  />

                  <Card
                    bordered={false}
                    className="implementation-rubric-indicator"
                  >
                    <div className="my-3">
                      <button
                        className={`button-3d ${
                          generationStatus === "generating" ? "generating" : ""
                        }`}
                        onClick={handleGenerateResponseClick}
                        disabled={
                          generationStatus === "generating"
                          || generationStatus === "generated"
                        }
                      >
                        {generationStatus === "idle" && "AI Text Enhancement"}
                        {generationStatus === "generating" && "Generating..."}
                        {generationStatus === "generated"
                          && "Response Generated"}
                      </button>

                      {generationStatus === "generated" && !hasPasted && (
                        <Tooltip title="Replace text ">
                          <RetweetOutlined
                            onClick={pasteGeneratedResponse}
                            style={{
                              fontSize: "24px",
                              cursor: "pointer",
                              color: "inherit",
                              marginLeft: "10px",
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>

                    {generatedResponse && (
                      <Card
                        type="inner"
                        title="Improvised Text"
                        className="mt-1"
                      >
                        <div style={{ padding: "10px" }}>
                          {generatedResponse}
                        </div>
                      </Card>
                    )}
                  </Card>
                </div>
              </span>
            </div>
          </>
        )}
        {indicator.rating === 4 && indicator.explanation && (
          <div className="share-response">
            {!(_.isNumber(indicator.rating) || indicator.explanation) && (
              <span>{indicator.index}</span>
            )}
            <div className="form-check new-check-form">
              <div className="mt-1">
                <input
                  id={`shared-${indicator.id}`}
                  className="form-check-input"
                  type="checkbox"
                  checked={indicator.shared}
                  onChange={shareResponse}
                  // style={{
                  //   accentColor: `${props.generalColor}`,
                  // }}
                />
              </div>
              <label
                className="form-check-label"
                htmlFor={`shared-${indicator.id}`}
              >
                I would like this response to be shared as an exemplar with the
                RSP online community.
              </label>
            </div>
          </div>
        )}
      </Col>

      <Col xs={24} sm={24} md={24} lg={6} className="indicatorDiv">
        <div>
          <div className="Ratingheaders">
            <span className="RatingTexts">{commonStrings.initiating}</span>
            <span className="RatingTexts">{commonStrings.developing}</span>
            <span className="RatingTexts">{commonStrings.integrated}</span>
          </div>

          <div className="d-flex mt-4">{indicatorRating(indicator)}</div>
        </div>

        {renderDatesScore()}
      </Col>
    </>
  )
}

export default ImplementationRubricIndicator
