import React from "react"
import { Menu, Checkbox } from "antd"

const CategoryMenu = ({
  categoryCounts,
  selectedIndicator,
  setSelectedIndicator,
  fullIndicatorList,
}) => (
  <Menu>
    {fullIndicatorList.map((indicator) => {
      const categoryItem = categoryCounts.find(
        (item) => item.category === indicator,
      )
      const count = categoryItem ? categoryItem.count : 0

      return (
        <Menu.Item
          key={indicator}
          onClick={() => setSelectedIndicator(indicator)}
        >
          <Checkbox checked={selectedIndicator === indicator}>
            {`${indicator} (${count})`}
          </Checkbox>
        </Menu.Item>
      )
    })}
  </Menu>
)

export default CategoryMenu
